<template lang="pug">
    .ai-info
        ._content
            ._group
                ._avatar-box
                    user-photo(:photo="avatar" :micro="false" :thumbs="false" :avatar-border="false")._avatar
                ._name {{ ai === "New chat" ? "Merlin AI" : ai }}
            img(:src="helper")._helper
            ._description(v-html="description")
</template>

<script>
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";

export default {
    name: "AiInfo",
    components: { UserPhoto },
    props: {
        ai: {
            type: String,
            default: "GPT",
            require: true,
        },
    },
    data() {
        return {
            midjourneyAvatar: require("../../../../assets/images/ai-logos/Midjorney.png"),
            stableDiffusionAvatar: require("../../../../assets/images/ai-logos/Stable Diffusion.png"),
            stableDiffusionVideoAvatar: require("../../../../assets/images/ai-logos/Stable Diffusion Video.png"),
            faceSwapAvatar: require("../../../../assets/images/ai-logos/Face Swap.png"),
            yandexAvatar: require("../../../../assets/images/ai-logos/Yandex GPT.png"),
            dalleAvatar: require("../../../../assets/images/ai-logos/DALLE-3.png"),
            runwayAvatar: require("../../../../assets/images/ai-logos/Runway.png"),
            pikaAvatar: require("../../../../assets/images/ai-logos/Pika.png"),
            merlinAvatar: require("../../../../assets/images/ai-logos/Merlin.png"),
            midjourneyHelper: require("../../../../assets/images/ai-helpers/Midjorney.png"),
            stableDiffusionHelper: require("../../../../assets/images/ai-helpers/Stable Diffusion.png"),
            stableDiffusionVideoHelper: require("../../../../assets/images/ai-helpers/Stable Diffusion Video.png"),
            faceSwapHelper: require("../../../../assets/images/ai-helpers/Face Swap.png"),
            runwayHelper: require("../../../../assets/images/ai-helpers/Pika.png"),
            pikaHelper: require("../../../../assets/images/ai-helpers/Pika.png"),
            merlinHelper: require("../../../../assets/images/ai-helpers/Merlin.png"),
        };
    },
    computed: {
        avatar: function () {
            if (this.ai === "Midjorney") {
                return this.midjourneyAvatar;
            }
            if (this.ai === "Stable Diffusion") {
                return this.stableDiffusionAvatar;
            }
            if (this.ai === "Stable Diffusion Video") {
                return this.stableDiffusionVideoAvatar;
            }
            if (this.ai === "Face Swap") {
                return this.faceSwapAvatar;
            }
            if (this.ai === "DALLE-3") {
                return this.dalleAvatar;
            }
            if (this.ai === "Pika") {
                return this.pikaAvatar;
            }
            if (this.ai === "Yandex GPT") {
                return this.yandexAvatar;
            }
            if (this.ai === "Runway") {
                return this.runwayAvatar;
            }

            return this.merlinAvatar;
        },
        helper: function () {
            if (this.ai === "Midjorney") {
                return this.midjourneyHelper;
            }
            if (this.ai === "Stable Diffusion") {
                return this.stableDiffusionHelper;
            }
            if (this.ai === "Stable Diffusion Video") {
                return this.stableDiffusionVideoHelper;
            }
            if (this.ai === "DALLE-3") {
                return this.stableDiffusionHelper;
            }
            if (this.ai === "Face Swap") {
                return this.faceSwapHelper;
            }
            if (this.ai === "Pika") {
                return this.pikaHelper;
            }
            if (this.ai === "Runway") {
                return this.runwayHelper;
            }

            return this.merlinHelper;
        },
        description: function () {
            if (this.ai === "Midjorney") {
                return "Здесь нейросеть может сгенерировать качественное изображение по текстовому описанию(промту). <br/><br/>Введите свой промт в поле внизу.";
            }
            if (this.ai === "Stable Diffusion") {
                return "Здесь нейросеть может сгенерировать качественное изображение по текстовому описанию(промту). <br/><br/>Введите свой промт в поле внизу.";
            }
            if (this.ai === "Face Swap") {
                return "Здесь нейросеть может вставить ваше лицо на любую фотографию. <br/><br/>Добавьте свой аватар и фото, на которое хотите вставить свое лицо.";
            }
            if (this.ai === "Pika") {
                return "Здесь нейросеть может создать короткое видео по текстовому описанию(промту). <br/><br/>Введите свой промт в поле внизу.";
            }
            if (this.ai === "Runway") {
                return "Здесь нейросеть может создать короткое видео по текстовому описанию(промту).<br/> А также она может анимировать Ваше изображение <br/><br/>Введите свой промт в поле внизу.";
            }
            if (this.ai === "Stable Diffusion Video") {
                return "Здесь нейросеть может анимировать Вашу картинку<br/><br/>Выберите картинку в поле ниже";
            }
            if (this.ai === "DALLE-3") {
                return "Здесь нейросеть может сгенерировать качественное изображение по текстовому описанию(промту). <br/><br/>Введите свой промт в поле внизу.";
            }

            return "Здесь нейросеть ответит на любой ваш вопрос.  <br/><br/>А также поможет написать контент план, пост, сценарий к короткому видео и тд. <br/><br/>Введите запрос в поле внизу.";
        },
    },
};
</script>

<style lang="scss" src="./ai-info.scss"></style>
