const rad2deg = (rad) => {
    return Math.round((rad * 180) / Math.PI);
};

const calcualteDirectionVector2d = (f) => {
    const x = (f.box[0] + f.box[2] / 2) - (f.box[3] * rad2deg(f.rotation.angle.yaw) / 90);
    const y = (f.box[1] + f.box[3] / 2) + (f.box[2] * rad2deg(f.rotation.angle.pitch) / 90);

    return { x, y };
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

class FaceValidationIndicator {
  static WIDTH = 2;

  constructor(ctx, x, y, radius, index, rotation) {
      this.ctx = ctx;
      this.x = x;
      this.y = y;
      this.radius = radius;
      this.index = index;
      this.rotation = rotation;
      this.height = 7;
      this.color = "rgba(255, 255, 255, 0.25)";
      this.isChecked = false;
  }

  draw() {
      this.ctx.save();
      this.ctx.translate(this.x, this.y);
      this.ctx.rotate(this.rotation);
      this.ctx.fillStyle = this.color;
      this.ctx.beginPath();
      this.ctx.roundRect(-1 * (FaceValidationIndicator.WIDTH / 2), -1 * (this.height / 2), FaceValidationIndicator.WIDTH, this.height, [2]);
      this.ctx.fill();
      this.ctx.restore();
  }

  setActive() {
      this.isActive = true;
      this.color = "rgba(158, 242, 129, 1)";
      this.height = 15;
  }
}

class IndicatorsController {
    static PROFILE_START_INDEX = 27;
    static PROFILE_END_INDEX = 33;
    
    constructor(indicators) {
        this.indicators = indicators;
    }

    drawIndicators() {
        this.indicators.forEach((indicator) => {
            indicator.draw();
        });
    }

    setActive(index) {
        const anyActive = this.indicators.some((indicator) => indicator.isActive);
        const isPrevActive = this.indicators[index + 1] && this.indicators[index + 1].isActive;
        
        if (!anyActive || isPrevActive || index === 59) {
            const { ctx } = this.indicators[index];
            this.indicators[index].setActive();
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            this.drawIndicators();
        }
    }

    getClosest(x, y) {
        const maxAllowedDistance = 95;
        const indexDistancePairs = [];

        this.indicators.forEach((indicator) => {
            const distanceX = indicator.x - x;
            const distanceY = indicator.y - y;

            const distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
            indexDistancePairs.push([indicator.index, distance]);
        });
        indexDistancePairs.sort((a, b) => a[1] - b[1]);
        const closest = indexDistancePairs[0];
        
        if (closest[1] < maxAllowedDistance) {
            return closest[0];
        }
        return -1;
    }
}

const base64ToFile = (base64, filename) => {
    let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
};


export { 
    rad2deg,
    calcualteDirectionVector2d,
    deg2rad,
    base64ToFile,
    FaceValidationIndicator,
    IndicatorsController
};
