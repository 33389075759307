<template lang="pug">
    .assistant-info
        ._group
        ._content
            ._group
                ._avatar-box
                    user-photo(:photo="assistant.avatar" :avatar-border="false")._avatar
                ._name {{ assistant.name }}
            ._description {{ assistant.bot_description }}
        ._helpers
            ._help(v-for="help in helpers" :key="help.id" @click="emitHelp(help)") {{ help.question }}
</template>

<script>
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";


export default {
    name: "AssistantInfo",
    components: {UserPhoto},
    props: {
        assistant: {
            type: Object,
            required: true,
            default: () => ({
                avatar: "",
                bot_description: "",
                is_bot_published: true,
                name: "",
                uuid: "",
            }),
        },
        helpers: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        emitHelp(help) {
            console.log("emit");
            this.$emit("showHelp", help);
        }
    },
};
</script>

<style lang="scss" src="./assistant-info.scss"></style>
