<template lang="pug">
    .plan-notification(:class="")
        ._title(v-html="title")
        ._description {{ description }}
        ._list
            ._list-item(v-for="(item, i) in list" :key="i") {{ item }}
        ui-button(v-if="newPlanName === 'FREE'" :text="btnText" :color="oldPlanName" @click.native="$modal.show('AISubscriptions')")._plan-btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "PlanNotification",
    components: { UiButton },
    props: {
        notification: {
            type: String,
            default: "plan-notification||26||26",
            required: true,
        },
    },
    data() {
        return {
            planName: {
                31: "FREE",
                27: "GOLD",
                33: "GOLD",
                26: "PLATINUM",
                32: "PLATINUM",
            },
        };
    },
    computed: {
        plans: (state) => state.notification.split("||"),
        oldPlan: (state) => +state.plans[1],
        newPlan: (state) => +state.plans[2],
        newPlanName: (state) => state.planName[state.newPlan],
        oldPlanName: (state) => state.planName[state.oldPlan],
        title: function () {
            if (this.newPlanName !== "FREE") {
                return `Вы активировали тариф <span class="${this.newPlanName}">${this.newPlanName}</span>`;
            }

            return `Ваш тариф <span class="${this.oldPlanName}">${this.oldPlanName}</span> закончился, теперь у Вас тариф FREE`;
        },
        description: function () {
            if (this.newPlanName !== "FREE") {
                return "Теперь Вам доступны:";
            }

            return "Ограничения тарифа FREE";
        },
        list: function () {
            let list = [
                "Ускоренная загрузка в Midjourney",
                "Доступ к Face Swap",
                "Использование ChatGPT v4",
            ];

            if (this.newPlanName === "FREE") {
                list = [
                    "Медленная генерация в Midjourney",
                    "Использование устаревшего ChatGPT v3.5",
                    "Нет доступа к Face Swap",
                ];
            }

            return list;
        },
        btnText: function () {
            return `Продлить тариф ${this.oldPlanName}`;
        },
    },
};
</script>

<style src="./plan-notification.scss" lang="scss"></style>
