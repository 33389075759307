<template lang="pug">
    .image-skeleton(:style="{'aspect-ratio': aspectRatio}" :data-height="height")
        transition(name="fade")
            img(v-if="isLoaded" :src="src")._image
            ._skeleton.-error(v-else-if="isError")
                svg-icon(icon-name="broken-image")._skeleton-icon.-error
                ._skeleton-error-text Не удалось загрузить изображение
            ._skeleton(v-else)
</template>

<script>
export default {
    name: "ImageSkeleton",
    props: {
        force: {
            type: String,
            default: "",
            required: false
        },
        src: {
            type: String,
            default: "",
            required: true,
        },
        height: {
            type: String,
            default: "400px",
            required: true,
        },
    },
    data() {
        return {
            isLoaded: false,
            isError: false,
            aspectRatio: "1/1",
        };
    },
    watch: {
        src() {
            this.waitImage();
        }
    },
    async mounted() {
        await this.waitImage().then(() => {
            this.isLoaded = true;
        });
    },
    methods: {
        async waitImage() {
            if(this.force !== "") {
                this.isLoaded = true;
                return;
            }
            await new Promise((resolve) => {
                let proxyImage = new Image();

                setTimeout(() => {
                    if(this.force !== "") {
                        return;
                    }
                    this.isError = true;
                }, 10000);

                proxyImage.addEventListener("load", () => {
                    this.aspectRatio = `${proxyImage.naturalWidth}/${proxyImage.naturalHeight}`;
                    setTimeout(() => {
                        resolve();
                    }, 100);
                });

                proxyImage.src = this.src;
            });
        },
    },
};
</script>

<style lang="scss" src="./image-skeleton.scss"></style>
