<template lang="pug">
    .course-banner
        ._close-btn(@click="hide")
            svg-icon(icon-name="cross")._cross-icon
        ._illustration
            svg-icon(icon-name="course/head")._illustration-head
        ._content
            ._title Не знаешь как написать промт?
            ._description Пройди обучение на оператора нейросети 
                br/ и стань в этом мастером.
        a(href="https://theneuraloperator.com/neurodesigher?utm_source=merlinface&utm_medium=banner&utm_campaign=mf" target="_blank")
            ui-button(type="bordered" color="gray" text="Получить бесплатный урок")._btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "CourseBanner",
    components: { UiButton },
    methods: {
        hide() {
            localStorage.setItem("courseBannerHidden", "true");
            this.$store.dispatch("set", {
                name: "courseBannerHidden",
                value: "true"
            });
        }
    }
};
</script>

<style lang="scss" src="./course-banner.scss"></style>
