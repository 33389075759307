<template lang="pug">
    .message-history-head
        ._user(:class="isUserWithAvatar" v-if="isDesktopView")
            router-link(:to="userLink" tag="img" :src="$asThumb(receiver.avatar, true) || NO_AVATAR_USER")._user-avatar
            ._user-data
                ._user-name(v-if="receiver.name === null") {{ locale("loading") }}
                ._user-name {{ receiver.name }}
                template(v-if="!isChatWithMerlinAI")
                    ._user-last-date(v-if="friendIsTyping" ) {{ locale("write") }}
                    ._user-last-date(v-else-if="friendOnline") {{ locale("online") }}
                    ._user-last-date(v-else-if="receiver.lastSeen") {{ smartLocalizedDate(receiver.lastSeen) }}
                template(v-else-if="receiver.isBot")
                    ._user-last-date {{ replyMessageCount }} 
                template(v-else)
                    ._user-last-date(v-if="activeThemeName === 'Midjorney'")
                        span(v-if="IS_NEURAL_FREE && !myUser.is_welcome_credit_active")._user-mode Медленный режим
                            ui-button(size="small" type="bordered" text="Ускорить" @click.native="$modal.show('AISubscriptions')")._update-btn
                        span(v-else) Турбо режим
                    ._user-last-date(v-else-if="activeThemeName === 'Runway'")
                        span(v-if="IS_NEURAL_FREE")._user-mode Модель: Gen-2
                            ui-button(size="small" type="bordered" text="Перейти на Gen-3" @click.native="$modal.show('AISubscriptions')")._update-btn
                        span(v-else) Gen-3
                    ._user-last-date(v-else-if="activeThemeName === 'DALLE-3' || activeThemeName === 'Pika' || activeThemeName === 'Stable Diffusion' || activeThemeName === 'Stable Diffusion Video' || activeThemeName === 'Face Swap' || activeThemeName === 'Yandex GPT'") {{ activeThemeName }}
                    ._user-last-date(v-else)
                        span(v-if="IS_NEURAL_FREE && !myUser.is_welcome_credit_active")._user-mode Chat GPT 4o-mini
                            ui-button(size="small" type="bordered" text="Перейти на GPT 4o" @click.native="$modal.show('AISubscriptions')")._update-btn
                        span(v-else) Chat GPT 4o
        ._mobile-menu(v-else)
            router-link(:to="`/messages/${isChatWithMerlinAI ? MERLIN_CONVERSATION_ID : ''}`" )._back-btn
                svg-icon(icon-name="arrow-left")._back-icon
            ._user-last-date(v-if="activeThemeName === 'Midjorney'")
                span(v-if="IS_NEURAL_FREE")._user-mode Медленный режим
                    ui-button(size="small" type="bordered" text="Ускорить" @click.native="$modal.show('AISubscriptions')")._update-btn
                span(v-else) Турбо режим
            ._user-last-date(v-else-if="activeThemeName === 'Runway'")
                span(v-if="IS_NEURAL_FREE")._user-mode Runway Gen-2
                    ui-button(size="small" type="bordered" text="Перейти на Gen-3" @click.native="$modal.show('AISubscriptions')")._update-btn
                span(v-else) Runway Gen-3
            ._user-last-date(v-else-if="activeThemeName === 'Pika' || activeThemeName === 'Stable Diffusion' || activeThemeName === 'Face Swap'") {{ activeThemeName }}
            ._user-last-date(v-else)
                span(v-if="IS_NEURAL_FREE")._user-mode Chat GPT 4o-mini
                    ui-button(size="small" type="bordered" text="Перейти на GPT 4o" @click.native="$modal.show('AISubscriptions')")._update-btn
                span(v-else) Chat GPT 4o
        ._bar
            ._likes(v-if="receiver.isBot")
                ._like-btn(@click="assistantVote('LIKE')" :class="{active: isVote ? localeVote === 'LIKE' : likes.myVote === 'LIKE'}")
                    svg-icon(icon-name="like")._like-icon
                    span.__like-count(v-if="isVote ? localeLikes : likes.likes") {{ isVote ? localeLikes : likes.likes }}
                ._like-btn(@click="assistantVote('DISLIKE')" :class="{active: isVote ? localeVote === 'DISLIKE' : likes.myVote === 'DISLIKE'}")
                    svg-icon(icon-name="like")._like-icon.dislike
                    span.__like-count(v-if="isVote ? localeDislikes : likes.dislikes") {{ isVote ? localeDislikes : likes.dislikes }}
            ._add-btn(@click="newChat" v-if="isChatWithMerlinAI && isNotAI && isNotAssistant")
                svg-icon(icon-name="new-chat")._add-icon
            ui-button(v-else-if="activeThemeName === 'Midjorney'" @click.native="$modal.show('midjourney-modal')" type="bordered" color="gray" size="small" text="Как использовать?")._modal-btn
            ._ctrl-btn(v-else-if="!isChatWithMerlinAI" @mousemove="dropdownStatus = true" @mouseleave="dropdownStatus = false" v-else)
                svg-icon(icon-name="more-dots")
                ._ctrl-popup(:class="{'message-history-head__ctrl-popup_active': dropdownStatus}")
                    ._ctrl-item(v-if="isChatWithMerlinAI" @click="newChat")
                        svg-icon(icon-name="plus")._ctrl-icon
                        ._ctrl-item-text {{ locale("new-chat") }}
                    ._ctrl-item(@click="showChatList")
                        svg-icon(icon-name="list")._ctrl-icon
                        ._ctrl-item-text {{ locale("history") }}
                    ._ctrl-item(v-if="!isChatWithMerlinAI" @click="confirmDeleteConversation()")
                        svg-icon(icon-name="cross")._ctrl-icon
                        ._ctrl-item-text {{ locale("delete-chat") }}
</template>

<script>
import gql from "graphql-tag";
import userTypingSubscription from "@/graphql/subscriptions/userTyping.subscription.graphql";
import newThemeMutation from "@/graphql/mutations/newTheme.mutation.graphql";
import assistantVoteMutation from "@/graphql/mutations/assistantVote.muation.graphql";
import deleteConversationMutation from "@/graphql/mutations/deleteConversation.mutation.graphql";
import UiButton from "../../../ui/ui-button/ui-button.vue";

export default {
    name: "MessageHistoryHead",
    components: { UiButton },
    props: {
        receiver: {
            type: Object,
            required: true,
            default: () => ({
                name: "",
                avatar: "",
                uuid: "",
                lastSeen: 0,
            }),
        },
        assistant: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        replyMessageCount: {
            type: Number,
            required: true,
            default: 0,
        },
        likes: {
            type: Object,
            required: false,
            default: () => ({
                likes: 0,
                dislikes: 0,
                myVote: "NONE"
            }),
        },
    },
    data() {
        return {
            friendIsTyping: false,
            friendOnline: false,
            friendLastSeen: 0,
            dropdownStatus: false,
            isVote: false,
            localeLikes: 0,
            localeDislikes: 0,
            localeVote: "NONE"
        };
    },
    computed: {
        isNotAI: function () {
            return (
                [
                    "Face Swap",
                    "Pika",
                    "Midjorney",
                    "Stable Diffusion",
                    "Stable Diffusion Video",
                    "Yandex GPT",
                    "DALLE-3",
                    "Runway"
                ].indexOf(this.activeThemeName) === -1
            );
        },
        isNotAssistant: (state) => !state.assistantId,
        assistantId: (state) => state.$route.params.assistantId,
        activeThemeName: (state) => state.$store.getters["activeThemeName"],
        chatId: (state) => state.$route.params.chatId,
        isUserWithAvatar: (state) => ({
            "message-history-head__user_with-avatar": state.isMobileView,
        }),
        isChatWithMerlinAI: (state) =>
            state.MERLIN_CONVERSATION_ID === state.chatId,
        userLink: function () {
            if (this.receiver.isBot) {
                return this.$route.path;
            } else {
                return `/person/${this.receiver.uuid}`;
            }
        },
    },
    mounted() {
        this.subscribeToReceiverWriting();
    },
    methods: {
        assistantVote(type) {
            this.$apollo
                .mutate({
                    mutation: gql(assistantVoteMutation),
                    variables: {
                        botId: this.assistantId,
                        type,
                    },
                    client: "chatClient",
                    fetchPolicy: "no-cache",
                })
                .then(r => {
                    const likes = r.data.assistantVote;
                    
                    this.isVote = true;
                    this.localeLikes = likes.likes;
                    this.localeDislikes = likes.dislikes;
                    this.localeVote = likes.myVote;
                    
                })
                .catch((e) => {
                    this.$msgBox("Произошла ошибка", e.graphQLErrors[0].message);
                });
        },
        subscribeToReceiverWriting() {
            this.$apollo.subscribe({
                query: gql(userTypingSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
            });
        },
        smartLocalizedDate(timestamp) {
            let today = new Date();
            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);

            const date = new Date(parseInt(timestamp));
            const minutes =
                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

            const timeDiff = Math.abs(today.getTime() - date.getTime());
            const diffMinutes = Math.floor(timeDiff / (1000 * 60));

            if (diffMinutes < 15) {
                return "Online";
            }

            if (today.toDateString() === date.toDateString())
                return "был(а) сегодня в " + date.getHours() + ":" + minutes;

            if (yesterday.toDateString() === date.toDateString())
                return "был(а) вчера, в " + date.getHours() + ":" + minutes;

            if (diffMinutes <= 43200) {
                return "был(а) недавно";
            }

            return "был(а) давно";
        },
        newChat() {
            this.$apollo
                .mutate({
                    mutation: gql(newThemeMutation),
                    client: "chatClient",
                })
                .then((r) => {
                    this.$emit("updateThemes");
                    this.$router.push(
                        `/messages/${this.MERLIN_CONVERSATION_ID}/themes/${r.data.newTheme.id}`,
                    );
                });
        },
        showChatList() {
            if (this.isChatWithMerlinAI) {
                this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}`);
            } else {
                this.$router.push("/messages");
            }
        },
        confirmDeleteConversation() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("confirmTitle"),
                text: this.locale("confirmDeleteAllMessagesText"),
                buttons: true,
                confirmText: this.locale("delete"),
                callback: this.deleteConversation,
            });
        },
        async deleteConversation() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${deleteConversationMutation}
                    `,
                    variables: {
                        conversationId: Number(this.conversationId),
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.$emit("deleteConversation", this.conversationId);
                    this.$router.replace("/messages");
                });
        },
    },
};
</script>

<style lang="scss" src="./message-history-head.scss"></style>
