<template lang="pug">
    .face-swap-verification
        template(v-if="IS_FACE_SWAP_NEED_PHOTOS")
            ._title Для использования Face Swap необходимо отсканировать Ваше лицо
            ._desc Нужно увидеть Ваше лицо с разных сторон, чтобы точнее наложить его на картинку
            //change-photos(:show-gift="false" type="anfas" :show-profile="false")._uploader
            ui-button(text="Начать" @click.native="$modal.show('face-verificator')")._verify-btn
        template(v-else-if="IS_FACE_SWAP_NEED_PLAN")
            ._title Функция Face Swap доступна на тарифах Gold и Platinum
            ._plans-list
                ._plan.-gold(v-if="goldPlan")
                    ._plan-name Gold
                    ._plan-price
                        span._plan-price-old(v-if="(+goldPlan.id === 27 || +goldPlan.id === 33) && myUser.is_discount_active") ${{ goldPlan.price * 2 }}
                        span ${{ goldPlan.price }} / месяц
                    ._plan-sale(v-if="(+goldPlan.id === 27 || +goldPlan.id === 33) && myUser.is_discount_active && timeDifferent > 0")
                        gift-timer(:time="timeDifferent")
                    ._plan-limit {{ goldPlan.chatMessagesLimit }} кредитов
                    ._plan-footer
                        ui-button(@click.native="subscribe(goldPlan.id)" text="Перейти на Gold")._plan-btn
                ._plan(v-if="platinumPlan")
                    ._plan-name Platinum
                    ._plan-price
                        span._plan-price-old(v-if="(+platinumPlan.id === 26 || +platinumPlan.id === 32) && myUser.is_discount_active") ${{ platinumPlan.price * 2 }}
                        span ${{ platinumPlan.price }} / месяц
                    ._plan-sale(v-if="(+platinumPlan.id === 26 || +platinumPlan.id === 32) && myUser.is_discount_active && timeDifferent > 0")
                        gift-timer(:time="timeDifferent")
                    ._plan-limit {{ platinumPlan.chatMessagesLimit }} кредитов
                    ._plan-footer
                        ui-button(@click.native="subscribe(platinumPlan.id)" text="Перейти на Platinum" color="platinum")._plan-btn
        ._title(v-else) Все условия для использования соблюдены
        ui-button(@click.native="cancelVerification" type="text" text="Отменить" v-if="faceSwapVerification")._cancel-btn
</template>

<script>
import ChangePhotos from "../../person/change-photos/change-photos.vue";
import gql from "graphql-tag";
import subscriptionPlansQuery from "@/graphql/queries/subscriptionPlans.query.graphql";
import subscribeToPlanMutation from "@/graphql/mutations/subscribeToPlan.mutation.graphql";
import UiButton from "../../ui/ui-button/ui-button.vue";
import GiftTimer from "../../person/change-photos/gift-timer/gift-timer.vue";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";

export default {
    name: "FaceSwapVerification",
    components: { GiftTimer, UiButton, ChangePhotos },
    data() {
        return {
            plans: [],
            intervalCount: 0,
            interval: null, // Интервал для получения моих таривоф
        };
    },
    computed: {
        timeDifferent: function () {
            const now = new Date();
            const endAt = new Date(this.myUser.discount_ends_at);

            return (endAt - now) / (1000 * 60);
        },
        faceSwapVerification: (state) =>
            state.$store.getters["faceSwapVerification"],
        platinumPlan: (state) =>
            state.plans.find((plan) => +plan.id === 26 || +plan.id === 32),
        goldPlan: (state) =>
            state.plans.find((plan) => +plan.id === 27 || +plan.id === 33),
    },
    async mounted() {
        await this.getPlans();
        this.interval = setInterval(() => {
            if (this.intervalCount >= 10) {
                clearTimeout(this.interval);
                this.interval = null;
                return;
            }
            this.getMySubscriptions();
        }, 1000);
    },
    destroyed() {
        clearInterval(this.interval);
        this.interval = null;
    },
    methods: {
        async getMySubscriptions() {
            await this.$apollo
                .query({
                    query: gql(getMySubscriptionsQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.intervalCount++;
                    this.$store.dispatch("user/set", {
                        name: "mySubscriptions",
                        value: r.data.getMySubscriptions,
                    });
                });
        },
        cancelVerification() {
            this.$store.dispatch("set", {
                name: "faceSwapVerification",
                value: false,
            });
        },
        async getPlans() {
            await this.$apollo
                .query({
                    query: gql(subscriptionPlansQuery),
                    variables: {
                        periodicity: "MONTHLY",
                        onlyChat: true,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.plans = r.data.subscriptionPlans[0].plans;
                });
        },
        subscribe(planId) {
            this.$apollo
                .mutate({
                    mutation: gql(subscribeToPlanMutation),
                    variables: {
                        plan: Number(planId),
                    },
                })
                .then((r) => {
                    window.location.href = r.data.subscribeToPlan;
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Выбранный план не найден",
                                );
                                break;
                            }
                            case 1005: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Не выбран ни один план",
                                );
                                break;
                            }
                            default: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Неизвестная ошибка",
                                );
                            }
                        }
                    }
                })
                .finally(() => {
                    this.$emit("close");
                });
        },
    },
};
</script>

<style lang="scss" src="./face-swap-verification.scss" scoped></style>
