<template lang="pug">
    modal(name="face-verificator" class="face-verificator-modal-wrap" :adaptive="true" height="560px" @before-close="beforeClose")
        .verification-modal
            ._wrap
                ._content
                    ._close-btn(@click="$modal.hide('face-verificator')")
                        svg-icon(icon-name="cross")._cross
                    ._title {{ title }}
                    transition(name="fadeIn")
                        ._description(v-if="verificationStatus !== 'success'") {{ description }}
                        ._no-description(v-else)
                    transition(name="fadeIn")
                        ._additional-desc(v-if="verificationStatus === 'in-progress'") Если они не меняют цвет - стоит повернуть голову сильнее.
                        ._no-description(v-else)
                    transition(name="fadeIn")
                        ._init(v-if="verificationStatus === 'init' || verificationStatus === 'uploading'" :class="{ 'uploading-loader': verificationStatus === 'uploading' }")
                            vue-lottie-player(autoplay controls loop mode="normal" :animationData="lottieAnimationFace")
                        ._camera(v-else-if="verificationStatus !== 'success'")
                            vue-web-cam(
                                ref="webcam"
                                v-if="faceDetector !== null"
                                select-first-device
                                playsinline
                                autoplay
                                :resolution="{width: 960, height: 960}"
                                @video-live="onVideoStream"
                                @error="onError"
                                @notsupported="onError"
                            )
                            ._canvas-container
                                canvas.face-canvas(ref='resultMarksCanvas' :style="{ opacity: verificationStatus === 'loading-camera' ? 0 : 1 }")
                                canvas.mask-canvas(ref='maskCanvas' :style="{ opacity: verificationStatus === 'loading-camera' ? 0 : 1 }")
                                canvas.indicators-canvas(ref='indicatorsCanvas' :style="{ opacity: verificationStatus === 'loading-camera' ? 0 : 1 }")
                                canvas.circle-canvas(ref='circleCanvas' :style="{ opacity: verificationStatus === 'distance-check' && verificationStatus !== 'loading-camera'  ? 1 : 0 }")
                                .camera-loader-container(:style="{ opacity: verificationStatus === 'loading-camera' ? 1 : 0 }")
                                    ._camera-loader {{ cameraLoadingTimer }}...

                        ._success-icon(v-else)
                            svg-icon(icon-name="success")
                    transition(name="fadeIn")
                        ui-button(v-if="verificationStatus === 'init'" :text="'начать'" @click.native="init")._button
                        ui-button(v-else-if="verificationStatus === 'success'" :text="'Отлично!'" @click.native="$modal.hide('face-verificator')")._button
</template>

<script>
import { WebCam } from "vue-web-cam";
import {
    calcualteDirectionVector2d,
    deg2rad,
    base64ToFile,
    FaceValidationIndicator,
    IndicatorsController,
} from "@/utils/face-detector";
import UiButton from "@/components/ui/ui-button/ui-button";
import Human from "@vladmandic/human";
import VueLottiePlayer from "vue-lottie-player";
import getPhotoUploadUrlQuery from "@/graphql/queries/getPhotoUploadUrl.query.graphql";
import setPersonPhotoMutation from "@/graphql/mutations/setPersonPhoto.mutation.graphql";
import taskGetQuery from "@/graphql/queries/taskGet.query.graphql";
import gql from "graphql-tag";
import {
    FACE_PAINTER_OPTIONS,
    FACE_DETECTOR_CONFIG,
} from "../../../settings/face-detector";

const VERIFICATION_STATUS = {
    INIT: "init",
    LOADING_CAMERA: "loading-camera",
    IN_PROGRESS: "in-progress",
    DISTANCE_CHECK: "distance-check",
    SUCCESS: "success",
    FAILED: "failed",
    UPLOADING: "uploading",
};

// Параметры для проверки дистанции
const MAX_DISTANCE = 0.55;
const MIN_DISTANCE = 0.45;

// Параметры для проверки поворота головы (влево/вправо)
const MAX_PITCH = 0.15;
const MIN_PITCH = -0.15;

// Параметры для проверки наклона головы (вверх/вниз)
const MAX_YAW = 0.15;
const MIN_YAW = -0.15;

// Параметр для поворота головы при сборе фото профиля
// чем больше - тем сильнее нужно повернуть голову
const ALLOW_YAW_THRESHOLD = 0.92;

export default {
    name: "FaceVerificatorModal",
    components: { "vue-web-cam": WebCam, UiButton, VueLottiePlayer },
    data() {
        return {
            faceDetector: null,
            blockDistanceChanges: false,
            faceVector: {
                x: -100,
                y: -100,
            },
            distance: -100,
            headPose: {
                yaw: -100,
                pitch: -100,
                roll: -100,
            },
            cameraLoadingTimer: 4,
            indicatorsController: null,
            verificationStatus: VERIFICATION_STATUS.INIT,
            anfasPhoto: null,
            // собираем несколько фото профиля (по аналогии с апкой)
            // сохраняем в map где ключ - индекс индикатора, значение - фото
            profilePhotos: {},
        };
    },
    computed: {
        lottieAnimationFace() {
            return require("../../../assets/lottie-files/analyze.json");
        },
        title() {
            switch (this.verificationStatus) {
                case VERIFICATION_STATUS.LOADING_CAMERA:
                case VERIFICATION_STATUS.DISTANCE_CHECK:
                    return "ваше лицо должно помещаться в круг";
                case VERIFICATION_STATUS.INIT:
                    return "Сканирование лица";
                case VERIFICATION_STATUS.SUCCESS:
                    return "Готово";
                case VERIFICATION_STATUS.IN_PROGRESS:
                    return "Отлично! Начнем:";
                default:
                    return "";
            }
        },
        description() {
            switch (this.verificationStatus) {
                case VERIFICATION_STATUS.INIT:
                    return "Нужно увидеть ваше лицо с разных сторон, чтобы точнее  наложить его на картинку";
                case VERIFICATION_STATUS.DISTANCE_CHECK: {
                    if (this.distance === -100) {
                        return "";
                    }
                    if (this.headPose.pitch > MAX_PITCH) {
                        return "Поднимите голову выше";
                    }
                    if (this.headPose.pitch < MIN_PITCH) {
                        return "Опустите голову ниже";
                    }
                    if (this.headPose.yaw > MAX_YAW) {
                        return "Поверните голову левее";
                    }
                    if (this.headPose.yaw < MIN_YAW) {
                        return "Поверни голову правее";
                    }
                    if (this.distance > MAX_DISTANCE) {
                        return "Приблизьтесь немного к камере";
                    }
                    if (this.distance < MIN_DISTANCE) {
                        return "Отдалитесь немного от камеры";
                    }

                    return "Отлично!";
                }
                case VERIFICATION_STATUS.IN_PROGRESS:
                    return "Медленно покрутите головой по часовой стрелке пока ве полоски вокруг не стали зелеными.";
                case VERIFICATION_STATUS.FAILED:
                    return "Ошибка камеры! Вставим сюда какую-то SVG-иконку с ошибкой";
                default:
                    return "";
            }
        },
    },
    methods: {
        async mounted() {
            setTimeout(() => {
                this.$modal.show("face-verificator");
            }, 1000);
        },
        beforeClose() {
            this.verificationStatus = VERIFICATION_STATUS.INIT;
            this.anfasPhoto = null;
            this.cameraLoadingTimer = 4;
        },
        init() {
            this.faceDetector = new Human(FACE_DETECTOR_CONFIG);
            this.faceDetector.warmup();
            this.verificationStatus = VERIFICATION_STATUS.LOADING_CAMERA;
        },
        onError() {
            this.verificationStatus = VERIFICATION_STATUS.FAILED;
        },
        setupCanvas() {
            const video = this.$refs.webcam.$el;
            const resultCtx = this.$refs.resultMarksCanvas.getContext("2d");

            resultCtx.canvas.width = video.videoWidth;
            resultCtx.canvas.height = video.videoHeight;
        },
        drawMask() {
            const resultCtx = this.$refs.resultMarksCanvas.getContext("2d");
            const maskCtx = this.$refs.maskCanvas.getContext("2d");

            maskCtx.fillStyle = "#222222";
            maskCtx.fillRect(0, 0, maskCtx.canvas.width, maskCtx.canvas.height);
            maskCtx.globalCompositeOperation = "xor";
            const width = resultCtx.canvas.width * 0.5;
            const height = this.isMobileView
                ? width
                : resultCtx.canvas.height * 0.5;
            maskCtx.arc(width, height, height * 0.8, 0, 2 * Math.PI);
            maskCtx.fill();

            resultCtx.drawImage(maskCtx.canvas, 0, 0);
        },
        async drawIndicators() {
            const indicatorsCtx = this.$refs.indicatorsCanvas.getContext("2d");
            const resultCtx = this.$refs.resultMarksCanvas.getContext("2d");
            indicatorsCtx.canvas.width = resultCtx.canvas.width;
            indicatorsCtx.canvas.height = this.isMobileView
                ? resultCtx.canvas.width
                : resultCtx.canvas.height;

            const bars = 60;
            const radius =
                (this.isMobileView
                    ? indicatorsCtx.canvas.width
                    : indicatorsCtx.canvas.height) * 0.45;

            const indicators = [];

            for (let i = 0; i < bars; i++) {
                const x =
                    radius * Math.cos(deg2rad((i * 360) / bars)) +
                    indicatorsCtx.canvas.width / 2;
                const y =
                    radius * Math.sin(deg2rad((i * 360) / bars)) +
                    indicatorsCtx.canvas.height / 2;

                const indicator = new FaceValidationIndicator(
                    indicatorsCtx,
                    x,
                    y,
                    radius,
                    i,
                    deg2rad(i * 6 + 90),
                );
                await new Promise((resolve) =>
                    setTimeout(() => {
                        indicator.draw();
                        indicators.push(indicator);
                        resolve();
                    }, 35),
                );
            }
            this.indicatorsController = new IndicatorsController(indicators);
        },
        async checkIndicatorsForChange() {
            const { x, y } = this.faceVector;

            if (
                this.verificationStatus ===
                    VERIFICATION_STATUS.LOADING_CAMERA &&
                !this.blockDistanceChanges
            ) {
                this.blockDistanceChanges = true;
                this.cameraLoadingTimer--;
                let timer = setInterval(() => {
                    this.cameraLoadingTimer--;
                }, 1000);
                await new Promise((resolve) =>
                    setTimeout(async () => {
                        this.verificationStatus =
                            VERIFICATION_STATUS.DISTANCE_CHECK;
                        this.blockDistanceChanges = false;
                        clearInterval(timer);
                        resolve();
                    }, 3500),
                );
            }

            if (
                this.verificationStatus ===
                    VERIFICATION_STATUS.DISTANCE_CHECK &&
                !this.blockDistanceChanges
            ) {
                const isPitchOk =
                    MAX_PITCH >= this.headPose.pitch &&
                    this.headPose.pitch >= MIN_PITCH;
                const isYawOk =
                    MAX_YAW >= this.headPose.yaw &&
                    this.headPose.yaw >= MIN_YAW;
                const isDistanceOk =
                    MAX_DISTANCE >= this.distance &&
                    this.distance >= MIN_DISTANCE;

                if (isPitchOk && isYawOk && isDistanceOk) {
                    this.blockDistanceChanges = true;

                    this.anfasPhoto = this.$refs.webcam.capture();
                    this.drawCircle(true);

                    await new Promise((resolve) =>
                        setTimeout(async () => {
                            this.verificationStatus =
                                VERIFICATION_STATUS.UPLOADING; //"in-progress";
                            await this.uploadPhotos();
                            // this.drawIndicators();
                            // this.blockDistanceChanges = false;
                            resolve();
                        }, 1500),
                    );
                }
            }

            if (
                this.verificationStatus === "in-progress" &&
                this.indicatorsController !== null &&
                !this.blockDistanceChanges
            ) {
                const closestIndex = this.indicatorsController.getClosest(x, y);

                if (closestIndex !== -1) {
                    const isProfileIndex =
                        IndicatorsController.PROFILE_START_INDEX <=
                            closestIndex &&
                        closestIndex <= IndicatorsController.PROFILE_END_INDEX;

                    // Дополнительная логика для проверки профиля
                    // для индикаторов с правой стороны необходимо проверить yaw параметр (поворот по оси Y)
                    // если он больше ALLOW_YAW_THRESHOLD, то линия окрашивится в зеленый
                    // и можно собирать фото
                    if (isProfileIndex) {
                        const { yaw } = this.headPose;

                        if (yaw <= ALLOW_YAW_THRESHOLD) {
                            return;
                        }

                        const needToSave = !Object.keys(
                            this.profilePhotos,
                        ).includes(closestIndex);
                        if (needToSave) {
                            this.profilePhotos[closestIndex] =
                                this.$refs.webcam.capture();
                        }
                    }

                    this.indicatorsController.setActive(closestIndex);
                    const isAnyInactiveLeft =
                        this.indicatorsController.indicators.some(
                            (indicator) => !indicator.isActive,
                        );
                    this.verificationStatus = isAnyInactiveLeft
                        ? VERIFICATION_STATUS.IN_PROGRESS
                        : VERIFICATION_STATUS.SUCCESS;
                }
            }
        },
        drawCircle(isActive = false) {
            const circleCtx = this.$refs.circleCanvas.getContext("2d");
            const resultCtx = this.$refs.resultMarksCanvas.getContext("2d");

            circleCtx.canvas.width = resultCtx.canvas.width;
            circleCtx.canvas.height = this.isMobileView
                ? resultCtx.canvas.width
                : resultCtx.canvas.height;

            circleCtx.strokeStyle = isActive
                ? "rgba(158, 242, 129, 1)"
                : "rgba(255, 255, 255, 0.25)";
            circleCtx.lineWidth = 3;

            const radius =
                (this.isMobileView
                    ? circleCtx.canvas.width
                    : circleCtx.canvas.height) * 0.45;

            circleCtx.arc(
                circleCtx.canvas.width / 2,
                circleCtx.canvas.height / 2,
                radius,
                0,
                2 * Math.PI,
            );
            circleCtx.stroke();
        },
        drawResults() {
            const detectionResult = this.faceDetector.next();
            const video = this.$refs.webcam.$el;
            const resultCtx = this.$refs.resultMarksCanvas.getContext("2d");
            const maskCtx = this.$refs.maskCanvas.getContext("2d");

            resultCtx.drawImage(
                video,
                0,
                0,
                video.videoWidth,
                video.videoHeight,
            );
            maskCtx.canvas.width = resultCtx.canvas.width;
            maskCtx.canvas.height = resultCtx.canvas.height;

            if (detectionResult) {
                const { face } = detectionResult;

                this.faceDetector.draw.face(
                    resultCtx.canvas,
                    face,
                    FACE_PAINTER_OPTIONS,
                );

                if (face && face.length > 0) {
                    const f = face[0];

                    if (!this.blockDistanceChanges) {
                        this.headPose = f.rotation.angle;
                        this.distance = f.distance;
                    }

                    // Проецируем вектор направления на основе yaw, pitch, roll
                    // https://en.wikipedia.org/wiki/Aircraft_principal_axes
                    this.faceVector = calcualteDirectionVector2d(f);

                    // Для проверки можно отрисовать кодом ниже
                    // maskCtx.beginPath();
                    // maskCtx.arc(this.faceVector.x, this.faceVector.y, 5, 0, 2 * Math.PI, true);
                    // maskCtx.fill();
                }

                // Отрисовка маски и запуск проверки отрисованных индикаторов для их изменения
                this.drawMask();
                this.checkIndicatorsForChange();
            }
            requestAnimationFrame(this.drawResults);
        },
        async uploadPhotos() {
            // eslint-disable-next-line no-unused-vars
            const anfasFile = base64ToFile(this.anfasPhoto, "anfas.jpg");
            const uploadUrl = await this.$apollo
                .query({
                    query: gql(getPhotoUploadUrlQuery),
                })
                .then((r) => {
                    return r.data.getPhotoUploadUrl;
                });
            const xhr = new XMLHttpRequest(),
                token =
                    localStorage.getItem("token") || this.$cookies.get("token");
            const onSuccess = this.setPhoto;
            xhr.onreadystatechange = async function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        const response = JSON.parse(this.responseText);

                        if (response.error === null) {
                            const photo =
                                response.http_host +
                                response.folder_name +
                                response.file_name;

                            await onSuccess(photo);
                        } else {
                            this.verificationStatus =
                                VERIFICATION_STATUS.FAILED;
                            return false;
                        }
                    } else {
                        this.verificationStatus = VERIFICATION_STATUS.FAILED;
                    }
                }
            };
            xhr.open("POST", uploadUrl + "&category=anfas", true);
            xhr.setRequestHeader("Authorization", token);
            xhr.send(anfasFile);
            console.error(uploadUrl);
        },
        async setPhoto(photoUrl) {
            await this.$apollo
                .mutate({
                    mutation: gql(setPersonPhotoMutation),
                    variables: {
                        uuid: this.myPerson.uuid,
                        photo: photoUrl,
                        category: "ANFAS",
                    },
                })
                .then((response) => {
                    const taskId = response.data.setPersonPhoto.taskId;
                    let pendingCounter = 0;
                    let intervalId;

                    intervalId = setInterval(() => {
                        this.$apollo
                            .query({
                                query: gql(taskGetQuery),
                                variables: {
                                    taskId,
                                },
                                fetchPolicy: "no-cache",
                            })
                            .then((response) => {
                                switch (response.data.taskGet.status) {
                                    case "COMPLETED":
                                        clearInterval(intervalId);
                                        this.$store.dispatch(
                                            "user/setTemporaryPhoto",
                                            {
                                                type: "anfas",
                                                photo: photoUrl,
                                            },
                                        );
                                        this.verificationStatus =
                                            VERIFICATION_STATUS.SUCCESS;
                                        break;

                                    case "ERROR":
                                        clearInterval(intervalId);
                                        this.verificationStatus =
                                            VERIFICATION_STATUS.FAILED;

                                        break;

                                    case "PENDING":
                                        if (pendingCounter >= 10) {
                                            clearInterval(intervalId);
                                            this.verificationStatus =
                                                VERIFICATION_STATUS.FAILED;
                                        }
                                        pendingCounter++;
                                        break;
                                }
                            })
                            .catch(() => {
                                clearInterval(intervalId);
                                this.verificationStatus =
                                    VERIFICATION_STATUS.FAILED;
                            });
                    }, 1200);
                })
                .catch((e) => {
                    const error = e.graphQLErrors[0]?.extensions?.code;
                    console.log(error);
                    this.verificationStatus = VERIFICATION_STATUS.FAILED;
                });
        },
        onVideoStream(stream) {
            if (stream !== undefined) {
                this.setupCanvas();
                this.faceDetector.video(this.$refs.webcam.$el);
                this.drawResults();
                this.drawCircle();
            }
        },
    },
};
</script>

<style lang="scss" src="./face-verificator-modal.scss" />
