<template lang="pug">
    .messages-history
        template(v-if="accessError")
            ._access-error
                svg-icon(icon-name="cross")._access-error-icon
                | {{ locale("accessError") }}
        template(v-else)
            loading._loading(v-if="isChatLoading")
            message-history-head._head(:receiver="receiver" :likes="likes" :replyMessageCount="replyMessageCount" :assistant="assistant" v-if="!assistantId || filteredMessages.length")
            perfect-scrollbar._list(v-if="filteredMessages.length" :options="scrollOptions" ref="scrollWrapper" infinite-wrapper @ps-scroll-y="scrollY")
                infinite-loading(direction="top" @infinite="getMessages" :identifier="infiniteId" v-if="initial")
                    ._message-loading(slot="spinner")
                        loading._message-loader
                    ._more-messages(slot="no-more")
                    ._no-messages(slot="no-results")
                        template(v-if="!filteredMessages.length")
                            span {{ locale("no-messages") }}
                chat-message(v-for="(message, index) in filteredMessages" :active-mj-keys="activeMJKeys" :is-last-message="index === filteredMessages.length - 1" :blockedUpscale="blockedUpscale" :message="message" :conversationId="conversationId" :key="message.id" :is-assistant="receiver.isBot" @describe-message="describeMessage")
            ._no-messages(v-else-if="showAiInfo")
                transition(name="fade")
                    assistant-info(v-if="receiver.isBot" :assistant="assistant" :helpers="helpers" @showHelp="showHelpMessage")
                    ai-info(v-else :ai="activeThemeName")
            add-message-form._send-message(:conversationId="conversationId" :describePrompt="describePrompt" :isBuyHidden="isBuyHidden" @stopWordsMessage="sendStopWordsError" @merlinFaceLink="merlinFaceLinkError" @onlyUrlPromptError="onlyUrlPromptError")
</template>

<script>
import MessageHistoryHead from "../message-history-head/message-history-head.vue";
import gql from "graphql-tag";
import friendChatTokenQuery from "@/graphql/queries/friendChatToken.query.graphql";
import startConversationQuery from "@/graphql/queries/startConversation.query.graphql";
import getMessagesQuery from "@/graphql/queries/getMessages.query.graphql";
import InfiniteLoading from "vue-infinite-loading";
import ChatMessage from "../message/message.vue";
import MerlinLimit from "../../merlin-limit/merlin-limit.vue";
import AddMessageForm from "../add-message-form/add-message-form.vue";
import editMessageSubscription from "@/graphql/subscriptions/editMessage.subscription.graphql";
import messageSendSubscription from "@/graphql/subscriptions/messageSend.subscription.graphql";
import blockMerlinChatSubscription from "@/graphql/subscriptions/blockMerlinChat.subscription.graphql";
import disableMidjourneySubscription from "@/graphql/subscriptions/disableMidjourney.subscription.graphql";
import readMessageMutation from "@/graphql/mutations/readMessage.mutation.graphql";
import getActiveMJKeyIdsQuery from "@/graphql/queries/getActiveMJKeyIds.query.graphql";
import AssistantInfo from "@/components/chat/messages/assistant-info/assistant-info.vue";
import getBotQuery from "@/graphql/queries/getBot.query.graphql";
import AiInfo from "@/components/chat/messages/ai-info/ai-info.vue";

export default {
    name: "MessagesHistory",
    components: {
        AiInfo,
        AssistantInfo,
        AddMessageForm,
        MerlinLimit,
        ChatMessage,
        MessageHistoryHead,
        InfiniteLoading,
    },
    data: () => ({
        scrollOptions: {
            suppressScrollX: true,
            swipeEasing: true,
            wheelSpeed: 2,
            wheelPropagation: true,
        },
        isChatLoading: true,
        accessError: false, // Если у юзера нету доступа к написанию сообщений какому-то юзеру
        friendChatToken: null, // Токен доступа к чату
        conversationId: 0, // ID чата
        conversation: {}, // чат
        receiver: {
            name: "",
            uuid: "",
            avatar: "",
            lastSeen: 0,
        },
        replyMessageCount: 0,
        likes: {
            likes: 0,
            dislikes: 0,
            myVote: "NONE",
        },
        page: 1,
        initial: false,
        messages: [],
        infiniteId: 1,
        uploadedImage: null,
        isImageLoading: false,
        blockedUpscale: {},
        autoScroll: false,
        scrollTop: 0,
        prevScrollPosition: -40,
        isBuyHidden: false,
        initialScroll: false,
        describePrompt: "",
        assistant: {},
        helpers: [],
        activeMJKeys: [],
        showAiInfo: true,
    }),
    computed: {
        chatId: (state) => state.$route.params.chatId,
        assistantId: (state) => state.$route.params.assistantId,
        themeId: (state) => state.$route.params.theme_id,
        type: (state) => state.$route.params.type,
        isChatWithMerlinAI: (state) =>
            state.MERLIN_CONVERSATION_ID === state.chatId,
        activeThemeName: (state) => state.$store.getters["activeThemeName"],
        filteredMessages: (state) =>
            state.messages.filter(
                (message) =>
                    +message.id !== +state.conversation.firstMessageId &&
                    message.message !== "start-assistant-message-id",
            ),
    },
    watch: {
        async assistantId() {
            this.isChatLoading = true;
            this.accessError = false;
            await this.startChat();
            await this.getAssistant();
            this.page = 1;
            this.initial = false;
            await this.getMessages();
            setTimeout(() => {
                this.isChatLoading = false;
            }, 1000);
            this.infiniteId++;
        },
        async chatId() {
            this.page = 1;
            this.initial = false;
            this.accessError = false;
            this.showAiInfo = false;
            await this.getMessages();
            this.showAiInfo = true;
            this.isChatLoading = false;
            this.infiniteId++;
        },
        async themeId() {
            this.page = 1;
            this.initial = false;
            this.showAiInfo = false;
            await this.getMessages();
            this.showAiInfo = true;
            this.isChatLoading = false;
            this.infiniteId++;
        },
        activeThemeName(name) {
            console.log(name);
        }
    },
    async mounted() {
        if (this.type !== "assistants") {
            await this.getFriendChatToken();
        }
        await this.startChat();
        await this.getAssistant();
        await this.getMessages();
        await this.subscribeToMoreMessages();
        await this.subscribeToMessageEdit();
        await this.subscribeToBlockMessages();
        await this.subscribeToBlockMidjourney();
        await this.getActiveMJKeys();
        this.isChatLoading = false;
    },
    methods: {
        describeMessage(text) {
            this.describePrompt = text;
        },
        sendStopWordsError(words) {
            try {
                this.messages.push({
                    id: new Date().getTime(),
                    error: true,
                    message: "В запросе обнаружены запрещённые слова: " + words,
                    image: false,
                    video: false,
                    senderId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    senderName: "Merlin AI",
                    senderPhoto:
                        "https://static.merlinface.com/photos/202401/S7BZERQnuwc.jpg",
                    senderPersonId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    createdAt: new Date().getTime(),
                    conversationId: this.MERLIN_CONVERSATION_ID,
                    reachTheGoal: null,
                    readByRecipient: false,
                    businessProposal: null,
                    stableDiffusion: null,
                    midjorney: null,
                    midjorneyData: null,
                    themeId: +this.themeId,
                    continue: false,
                    showStopButton: false,
                    isUserStopGenerating: false,
                    copied: false,
                });
                this.scrollBottom();
            } catch (e) {
                console.log(e);
            }
        },
        showHelpMessage(help) {
            console.log("showHeloMessageMethod");
            this.messages.push({
                id: new Date().getTime(),
                error: false,
                message: help.question,
                image: false,
                video: false,
                senderId: this.myPerson.uuid,
                senderName: this.myPerson.name,
                senderPhoto: this.myPerson.photos.avatar,
                senderPersonId: this.myPerson.uuid,
                createdAt: new Date().getTime(),
                conversationId: this.uuid,
                reachTheGoal: null,
                readByRecipient: false,
                businessProposal: null,
                stableDiffusion: null,
                midjorney: null,
                midjorneyData: null,
                themeId: null,
                continue: false,
                showStopButton: false,
                isUserStopGenerating: false,
                copied: false,
            });

            setTimeout(() => {
                this.messages.push({
                    id: new Date().getTime(),
                    error: false,
                    message: help.answer,
                    image: false,
                    video: false,
                    senderId: this.uuid,
                    senderName: this.receiver.name,
                    senderPhoto: this.receiver.avatar,
                    senderPersonId: this.receiver.uuid,
                    createdAt: new Date().getTime(),
                    conversationId: this.uuid,
                    reachTheGoal: null,
                    readByRecipient: false,
                    businessProposal: null,
                    stableDiffusion: null,
                    midjorney: null,
                    midjorneyData: null,
                    themeId: null,
                    continue: false,
                    showStopButton: false,
                    isUserStopGenerating: false,
                    copied: false,
                });
            }, 1500);
            this.scrollBottom();
        },
        merlinFaceLinkError() {
            try {
                this.messages.push({
                    id: new Date().getTime(),
                    error: true,
                    message:
                        "В запросе обнаружена ссылка на наш сайт. Для загрузки изображения, кликните на иконку слева от поля ввода текста и выберите нужную картинку с Вашего устройства.",
                    image: false,
                    video: false,
                    senderId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    senderName: "Merlin AI",
                    senderPhoto:
                        "https://static.merlinface.com/photos/202401/S7BZERQnuwc.jpg",
                    senderPersonId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    createdAt: new Date().getTime(),
                    conversationId: this.MERLIN_CONVERSATION_ID,
                    reachTheGoal: null,
                    readByRecipient: false,
                    businessProposal: null,
                    stableDiffusion: null,
                    midjorney: null,
                    midjorneyData: null,
                    themeId: +this.themeId,
                    continue: false,
                    showStopButton: false,
                    isUserStopGenerating: false,
                    copied: false,
                });
                this.scrollBottom();
            } catch (e) {
                console.log(e);
            }
        },
        onlyUrlPromptError() {
            try {
                this.messages.push({
                    id: new Date().getTime(),
                    error: true,
                    message:
                        "Запрос не может состоять только из ссылки. Пожалуйста, добавьте описание того, что бы Вы хотели сделать с фотографией",
                    image: false,
                    video: false,
                    senderId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    senderName: "Merlin AI",
                    senderPhoto:
                        "https://static.merlinface.com/photos/202401/S7BZERQnuwc.jpg",
                    senderPersonId: "193c490c-b1cd-11ed-896d-02420a000cf6",
                    createdAt: new Date().getTime(),
                    conversationId: this.MERLIN_CONVERSATION_ID,
                    reachTheGoal: null,
                    readByRecipient: false,
                    businessProposal: null,
                    stableDiffusion: null,
                    midjorney: null,
                    midjorneyData: null,
                    themeId: +this.themeId,
                    continue: false,
                    showStopButton: false,
                    isUserStopGenerating: false,
                    copied: false,
                });
                this.scrollBottom();
            } catch (e) {
                console.log(e);
            }
        },
        convertToHtml(inputText) {
            const regex = /\[(.*?)\]\((.*?)\)/g;
            if (regex.test(inputText)) {
                return inputText.replace(regex, (match, text, link) => {
                    return `<a href="${link}" target="_blank">${text}</a>`;
                });
            }
            return inputText;
        },
        scrollY() {
            const nowScroll = this.$refs.scrollWrapper.$el.scrollTop;

            if (this.initialScroll) {
                this.isBuyHidden = this.prevScrollPosition < nowScroll;
                this.prevScrollPosition = nowScroll;
            }

            this.initialScroll = true;

            if (nowScroll < this.scrollTop) {
                this.autoScroll = false;
            }
        },
        async subscribeToBlockMessages() {
            const observer = this.$apollo.subscribe({
                query: gql(blockMerlinChatSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    if (
                        r.data.blockMerlinChat.receiverId === state.myUser.uuid
                    ) {
                        state.$store.dispatch("set", {
                            name: "merlinChatBlocked",
                            value: r.data.blockMerlinChat.status,
                        });
                    }
                    console.log(r.data.blockMerlinChat);
                    if (!r.data.blockMerlinChat.status) {
                        const msg = state.messages.find(
                            (message) => message.showStopButton,
                        );
                        msg.showStopButton = false;
                    }
                },
                error() {
                    this.$store.dispatch("set", {
                        name: "merlinChatBlocked",
                        value: false,
                    });
                },
            });
        },
        async subscribeToBlockMidjourney() {
            const observer = this.$apollo.subscribe({
                query: gql(disableMidjourneySubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    if (
                        r.data.disableMidjourney.receiverId ===
                        state.myUser.uuid
                    ) {
                        state.$emit("disableMidjourney", true);
                    }
                },
                error() {
                    this.$store.dispatch("set", {
                        name: "merlinChatBlocked",
                        value: false,
                    });
                },
            });
        },
        async getActiveMJKeys() {
            await this.$apollo
                .query({
                    query: gql(getActiveMJKeyIdsQuery),
                    client: "chatClient",
                })
                .then((r) => {
                    this.activeMJKeys = r.data.getActiveMJKeyIds;
                });
        },
        async subscribeToMoreMessages() {
            const observer = this.$apollo.subscribe({
                query: gql(messageSendSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    const newMessage = r.data.messageSent;

                    if (
                        +newMessage.conversationId === +state.conversationId &&
                        newMessage.senderId !== state.myUser.uuid
                    ) {
                        state.readMessage(+newMessage.id);
                    }

                    state.$set(state.blockedUpscale, newMessage.id, []);

                    if (String(newMessage.image) === "null") {
                        state.$set(newMessage, "image", false);
                        state.$set(newMessage, "small_image", false);
                        state.$set(newMessage, "video", false);
                    } else {
                        if (typeof newMessage.image !== "string") return;
                        if (newMessage.image.indexOf(".mp4") >= 0) {
                            state.$set(newMessage, "video", newMessage.image);
                            state.$set(newMessage, "image", false);
                            state.$set(newMessage, "small_image", false);
                        } else {
                            state.$set(newMessage, "video", false);
                            state.$set(newMessage, "image", {
                                loaded: false,
                                url: newMessage.image,
                            });
                            state.$set(newMessage, "small_image", {
                                loaded: false,
                                url: newMessage.small_image,
                            });
                        }
                    }

                    /* Если это ответ от GPT -> включаем автоскролл */
                    if (newMessage.message === "gpt-loading") {
                        state.autoScroll = true;
                    }

                    state.$set(
                        newMessage,
                        "showStopButton",
                        newMessage.message === "gpt-loading",
                    );

                    state.$set(newMessage, "isUserStopGenerating", false);

                    state.$set(newMessage, "copied", false);
                    newMessage.message = state.convertToHtml(
                        newMessage.message,
                    );
                    /* Пушим сообщение в мерлина только если в активном состоянии находится та тема, в которую пришёл ответ */
                    if (this.isChatWithMerlinAI) {
                        if (+this.themeId === newMessage.themeId) {
                            state.messages.push(newMessage);
                        }
                    } else {
                        state.messages.push(newMessage);
                    }

                    state.scrollBottom();
                },
                error(error) {
                    console.log(error);
                },
            });
        },
        async subscribeToMessageEdit() {
            const observer = this.$apollo.subscribe({
                query: gql(editMessageSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    const result = r.data.messageEdited;

                    if (result.conversationId === state.conversationId) {
                        const msg = state.messages.find(
                            (message) => message.id === result.id,
                        );

                        if (msg.isUserStopGenerating) {
                            return (msg.showStopButton = false);
                        }
                        if (msg?.id) {
                            msg.message = result.message;
                            msg.message = state.convertToHtml(result.message);
                            if (String(result.image) !== "null") {
                                if (result.image.indexOf(".mp4") >= 0) {
                                    msg.video = result.image;
                                    msg.image = false;
                                } else {
                                    msg.image.url = result.image;
                                }
                            } else {
                                msg.image = false;
                            }

                            msg.midjorneyData = result.midjorneyData;

                            console.log("Кнопка продолжить", result.continue);
                            msg.continue = result.continue;
                            msg.type = result.type;
                            msg.queue = result.queue;
                            msg.api_hash = result.api_hash;

                            if (state.autoScroll) {
                                state.scrollBottom();
                            }
                        }
                    }
                },
                error(error) {
                    console.log(error);
                },
            });
        },
        readMessage(messageId) {
            this.$apollo.mutate({
                mutation: gql(readMessageMutation),
                variables: {
                    messageId,
                    conversationId: +this.conversationId,
                },
                client: "chatClient",
            });
        },
        /* Получаем токен доступа к чату */
        async getFriendChatToken() {
            await this.$apollo
                .query({
                    query: gql(friendChatTokenQuery),
                    variables: {
                        friendId: this.chatId,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.friendChatToken = r.data.friendChatToken;
                })
                .catch((e) => {
                    this.accessError = true;
                    if (e.graphQLErrors[0].extensions.code) {
                        // Доработать бэк, чтобы он удалял такие переписки
                        console.log(e);
                    }
                });
        },
        /* Запускаем чат */
        async startChat() {
            const isAssistant = !!this.assistantId;
            await this.$apollo
                .query({
                    client: "chatClient",
                    query: gql(startConversationQuery),
                    variables: {
                        conversationToken: isAssistant
                            ? `assistant_${this.assistantId}`
                            : this.friendChatToken,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(async (response) => {
                    const conversation = response.data.startConversation;

                    this.helpers = [];
                    if (conversation.conversation.helpers) {
                        const h = JSON.parse(conversation.conversation.helpers);
                        this.helpers = h.filter((h) => h.show);
                    }

                    /* Делаем эмит, чтобы отобразить чат в списке чатов, если он новый */
                    this.$emit("initConversation", conversation);

                    this.conversation = conversation.conversation;
                    this.conversationId = conversation.id;
                    this.receiver = {
                        name: this.formatUserName(conversation.withName),
                        avatar: conversation.withAvatar,
                        uuid: conversation.withPersonId,
                        lastSeen: response.data.startConversation.lastSeen,
                        isBot: conversation.conversation.isBot,
                    };
                    this.replyMessageCount =
                        conversation.conversation.replyMessageCount;
                    this.likes = conversation.conversation.likes;
                })
                .catch((error) => {
                    this.accessError = true;
                    console.log("404", error.graphQLErrors[0].extensions.code);
                });
        },
        async getAssistant() {
            if (!this.assistantId) return;
            await this.$apollo
                .query({
                    query: gql(getBotQuery),
                    variables: {
                        uuid: this.assistantId,
                    },
                })
                .then((r) => {
                    this.assistant = r.data.getBot;
                });
        },
        /* Получаем сообщения */
        async getMessages($state) {
            const isAssistant = !!this.assistantId;

            if (this.page === 1) {
                this.messages = [];
            }

            await this.$apollo
                .query({
                    query: gql(getMessagesQuery),
                    variables: {
                        receiverId: isAssistant
                            ? this.assistantId
                            : this.chatId,
                        page: this.page,
                        limit: 10,
                        term: null,
                        themeId: this.isChatWithMerlinAI ? +this.themeId : 0,
                    },
                    fetchPolicy: "no-cache",
                    client: "chatClient",
                })
                .then((r) => {
                    const newMessages = r.data.getMessages.messages;

                    if (newMessages.length) {
                        const minMessageId = newMessages.reduce(
                            (min, current) => {
                                return current.id < min.id ? current : min;
                            },
                            newMessages[0],
                        );

                        this.page = +minMessageId.id;

                        newMessages.forEach((newMessage) => {
                            if (
                                typeof this.blockedUpscale[newMessage.id] ===
                                "undefined"
                            ) {
                                this.$set(
                                    this.blockedUpscale,
                                    newMessage.id,
                                    [],
                                );
                            } else {
                                this.$set(
                                    this.blockedUpscale,
                                    newMessage.id,
                                    this.blockedUpscale[newMessage.id],
                                );
                            }

                            if (String(newMessage.image) === "null") {
                                this.$set(newMessage, "image", false);
                                this.$set(newMessage, "small_image", false);
                                this.$set(newMessage, "video", false);
                            } else {
                                if (typeof newMessage.image !== "string")
                                    return;
                                if (newMessage.image.indexOf(".mp4") >= 0) {
                                    this.$set(
                                        newMessage,
                                        "video",
                                        newMessage.image,
                                    );
                                    this.$set(newMessage, "image", false);
                                    this.$set(newMessage, "small_image", false);
                                } else {
                                    this.$set(newMessage, "video", false);
                                    this.$set(newMessage, "image", {
                                        loaded: false,
                                        url: newMessage.image,
                                    });
                                    this.$set(newMessage, "small_image", {
                                        loaded: false,
                                        url: newMessage.small_image,
                                    });
                                }
                            }

                            this.$set(
                                newMessage,
                                "showStopButton",
                                newMessage.message === "gpt-loading",
                            );

                            this.$set(
                                newMessage,
                                "isUserStopGenerating",
                                false,
                            );

                            this.$set(newMessage, "copied", false);
                            newMessage.message = this.convertToHtml(
                                newMessage.message,
                            );
                            this.messages.unshift(newMessage);
                        });
                        if ($state) {
                            $state.loaded();
                        }
                    } else {
                        if ($state) {
                            $state.complete();
                        }
                    }
                    if (!this.initial) {
                        this.initial = true;
                        this.scrollBottom();
                    }
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].message === "Midjourney disabled") {
                        this.$emit("disableMidjourney", true);
                    }
                });
        },
        scrollBottom() {
            console.log("scrollBottom");
            this.$nextTick(() => {
                this.isBuyHidden = true;
                this.$refs.scrollWrapper.$el.scrollTop =
                    this.$refs.scrollWrapper.$el.scrollHeight -
                    this.$refs.scrollWrapper.$el.clientHeight;
                this.scrollTop = this.$refs.scrollWrapper.$el.scrollTop;
            });
        },
    },
};
</script>

<style lang="scss" src="./messages-history.scss"></style>
