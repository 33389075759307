export const FACE_PAINTER_OPTIONS = {
    drawLabels: false,
    drawBoxes: false,
    drawGaze: false,
    color: "white",
};

export const FACE_DETECTOR_CONFIG = {
    backend: "webgpu",
    warmup: "face",
    modelBasePath: "https://merlinface.com/models/",
    filter: {
        enabled: false,
    },
    gesture: {
        enabled: false,
    },
    body: {
        enabled: false,
    },
    hand: {
        enabled: false,
    },
    object: {
        enabled: false,
    },
    segmentation: {
        enabled: false,
    },
    face: {
        enabled: true,
        antispoof: {
            enabled: false,
        },
        attention: {
            enabled: false,
        },
        description: {
            enabled: false,
        },
        emotion: {
            enabled: false,
        },
        gear: {
            enabled: false,
        },
        iris: {
            enabled: true,
        },
        liveness: {
            enabled: false,
        },
    }
};
