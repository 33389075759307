<template lang="pug">
    .face-swap-changer
        transition(name="fade")
            loading(v-if="isFaceSwapLoading")._loading
        ._actions
            ._group
                ._group-avatar-box.-zoom
                    user-photo(:photo="photoFrom" :micro="false" @click.native="showPhoto(photoFrom)")._group-avatar
                ._group-info
                    ._group-title Откуда
                        span берём лицо
                    ui-button(size="small" color="gray" type="bordered" @click.native="$modal.show('face-swap-choose-photo')")._change-btn
                        template(v-slot:content)
                            svg-icon(icon-name="catalog")._change-icon
                            | Выбрать другое
            ._group
                ._group-avatar-box(:class="{'face-swap-changer__group-avatar-box_zoom': targetPhoto}")
                    loading(v-if="targetLoading")._target-loading
                    user-photo( :photo="targetPhoto" :micro="false" :thumbs="false" @click.native="showPhoto(targetPhoto)")._group-avatar
                ._group-info
                    ._group-title Куда
                        span вставляем лицо
                    ui-button(size="small" color="gray" type="bordered" @click.native="uploadPhoto" :disabled="targetLoading")._change-btn
                        template(v-slot:content)
                            svg-icon(icon-name="upload-photo")._change-icon
                            | Загрузить фото
        ui-button(size="small" :disabled="!photoFrom || !targetPhoto" @click.native="submit")._btn
            template(v-slot:content)
                svg-icon(icon-name="message")._message-icon
                | Заменить лицо
</template>

<script>
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "FaceSwapChanger",
    components: { UiButton, UserPhoto },
    props: {
        targetPhoto: {
            type: [String, Object],
            required: false,
            default: () => "",
        },
        targetLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFaceSwapLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        photoFrom: (state) => state.$store.getters["faceSwapPhoto"],
    },
    mounted() {
        this.clear();
    },
    methods: {
        showPhoto(photo) {
            if(!photo) return;
            this.$modal.show("Image", { image: photo });
        },
        clear() {
            let photo = this.myPerson.photos.anfas;

            if (this.myPerson.photos.avatar) {
                photo = this.myPerson.photos.avatar;
            }
            this.$store.dispatch("set", {
                name: "faceSwapPhoto",
                value: photo,
            });
            this.$emit("clear-photo");
        },
        uploadPhoto() {
            this.$emit("upload-photo");
        },
        submit() {
            this.$emit("submit-form");
        },
    },
};
</script>

<style lang="scss" src="./face-swap-changer.scss"></style>
