<template lang="pug">
    .content__main.-without-right-aside
        .chat-server-error(v-if="chatServerError")
            svg-icon(icon-name="chat-error")._icon
            ._caption
                ._title Мы добавляем новые функции в Merlin AI
                ._desc Это займет не более 20 минут
                    br/br/ Скоро общение с нейросетью станет еще интереснее.
        .chat-page(v-else :class="{full: hideMobileMenu}")
            ._list(v-show="!isMobileView || !isShowChat")
                dialogs(@chat-server-error="chatServerError = true" @enableMidjourney="midjourneyDisabled = $event" :deleteConversation="deleteConversation" :themesUpdater="themesCounter" :newConversation="newConversation" :readConversationId="readConversationId" :readConversationTime="readConversationTime")
            ._content(v-if="chatId || !isMobileView")
                face-swap-verification(v-if="((IS_FACE_SWAP_NEED_PHOTOS || IS_FACE_SWAP_NEED_PLAN) && +chatFaceSwapThemeId === +themeId) || faceSwapVerification")
                messages-history(v-if="isShowChat && !isLoadingChat" :chatId="chatId" @showList="fixedActive = true" @updateThemes="themesCounter++" @disableMidjourney="midjourneyDisabled = true" @deleteConversation="deleteConversationAction" @initConversation="addNewConversation" @readConversation="readConversation")
                ._select-chat(v-else)
                    svg-icon(icon-name="message")._select-chat-icon
                    ._select-chat-text {{ text }}
        portal(to="modals")
            face-verificator-modal
</template>
<script>
import MessagesHistory from "../components/chat/messages/messages-history/messages-history.vue";
import Dialogs from "../components/chat/dialogs/dialogs";
import FaceSwapVerification from "../components/chat/face-swap-verification/face-swap-verification.vue";
import FaceVerificatorModal from "@/components/modals/face-verificator-modal/face-verificator-modal.vue";

export default {
    name: "Messages",
    components: {
        FaceVerificatorModal,
        FaceSwapVerification,
        Dialogs,
        MessagesHistory,
    },
    data() {
        return {
            themesCounter: 0,
            isLoadingChat: false,
            deleteConversation: false,
            newConversation: {},
            readConversationId: 0,
            readConversationTime: null,
            merlinConversationId: "18d68acc-b1cd-11ed-b5e6-02420a000cf6",
            fixedActive: false,
            chatServerError: false,
            midjourneyDisabled: false,
        };
    },
    computed: {
        faceSwapVerification: (state) =>
            state.$store.getters["faceSwapVerification"],
        hideMobileMenu: (state) => state.$store.getters["hideMobileMenu"],
        chatMidjourneyThemeId: (state) =>
            state.$store.getters["chatMidjourneyThemeId"],
        chatFaceSwapThemeId: (state) =>
            state.$store.getters["chatFaceSwapThemeId"],
        chatId: (state) => state.$route.params.chatId || false,
        assistantId: (state) => state.$route.params.assistantId || false,
        themeId: (state) => state.$route.params.theme_id,
        text: function () {
            if (this.chatId === this.merlinConversationId) {
                return this.locale("chooseAI");
            }

            if (this.isLoadingChat) {
                return this.locale("loading");
            }

            return this.locale("chooseChat");
        },
        isShowChat: function () {
            if (this.chatId === this.merlinConversationId) {
                return !!this.themeId || !!this.assistantId;
            }
            return this.chatId;
        },
    },
    watch: {
        themeId() {
            this.fixedActive = false;
        },
        chatId() {
            this.fixedActive = false;
            this.loadingChat();
        },
    },
    mounted() {
        this.merlinConversationId = process.env.MERLIN_CONVERSATION_ID
            ? process.env.MERLIN_CONVERSATION_ID
            : this.merlinConversationId;
        this.$store.dispatch("setPage", "messages");
    },
    methods: {
        loadingChat() {
            this.isLoadingChat = true;
            setTimeout(() => {
                this.isLoadingChat = false;
            }, 500);
        },
        deleteConversationAction(id) {
            this.deleteConversation = id;
        },
        addNewConversation(conversation) {
            this.newConversation = conversation;
        },
        readConversation(id) {
            this.readConversationId = id;
            this.readConversationTime = new Date().getTime();
        },
    },
};
</script>

<style lang="scss">
.chat-server-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20px);
    padding: 20px;
    @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;
    }

    &__icon {
        width: 300px;
        fill: none;
        @media (max-width: 767px) {
            width: 240px;
        }
    }

    &__caption {
        padding-left: 40px;
        @media (max-width: 767px) {
            padding-left: 0;
            padding-top: 40px;
        }
    }

    &__title {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }

    &__desc {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        margin-top: 15px;
    }
}

.chat-page {
    display: flex;
    height: calc(100vh - 80px);
    overflow: hidden;
    transition: height 0.4s;
    @media (max-width: 990px) {
      height: calc(100dvh - 15px);
    }

    &__list {
        background: #282828;
        flex: none;
        margin-right: 20px;

        @media (max-width: 990px) {
            width: 100%;

            &.fixed {
                position: fixed;
                left: -100%;
                top: 15px;
                margin-right: 0;
                display: flex !important;
                width: calc(100vw - 30px);
                height: calc(94dvh - 30px);
                z-index: 10;
                background: #222;
                transition: 0.4s;
            }

            &.fixed-active {
                left: 15px;
            }
        }
    }

    &__content {
        flex-grow: 1;
        background: #282828;
    }

    &__select-chat {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__select-chat-icon {
        width: 50px;
        fill: none;
        stroke: #868686;
    }

    &__select-chat-text {
        margin-top: 25px;
        color: #868686;
        font-size: 18px;
    }
}
</style>
