<template lang="pug">
    .merlin-limit
        ._content
            ._title {{ locale("title") }}
            ._desc {{ locale("desc") }}
        ._btns
            ui-button._btn(:text="locale('btn')" size="small" @click.native="showModal")
            ui-button(type="bordered" size="small" text="Ввести промокод" color="gray" @click.native="$modal.show('Promocode')")._promocode-btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "MerlinLimit",
    components: {UiButton},
    methods: {
        showModal() {
            this.$modal.show("AISubscriptions");
            this.yandexGoal("openAIPopup", false);
        }
    }
};
</script>

<style lang="scss" src="./merlin-limit.scss"></style>
